import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { graphql } from "gatsby"

const StyledHeroImage = styled(Image)`
  width: 100%;
  margin: 0 auto;
`

const StyledContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
`

const StyledSectionTitle = styled.h2`
  color: #da1e35;
  font-size: 32px;
  text-align: center;
  padding: 0 20px;
`

const StyledSection = styled("div")`
  display: flex;
  flex-direction: column;

  p {
    color: #000;
    font-size: 15px;
    padding: 0 20px;
    text-align: justify;
  }
`

const StyledListParagraph = styled.p`
  color: #000;
  font-size: 15px;
  padding: 0 20px;
  text-align: justify;
  margin: 5px 0;
`

const StyledBoldParagraph = styled.p`
  color: #000;
  font-weight: 600;
  font-size: 15px;
  padding: 0 20px;
  text-align: justify;
  margin: 15px 0 30px;
`

const PaintingPage = ({ data }) => {
  return (
    <>
      <StyledHeroImage fluid={data.hero_image_painting.childImageSharp.fluid} />
      <StyledContentWrapper
        image={data.maps_background.childImageSharp.fluid.src}
      >
        <StyledSection>
          <StyledSectionTitle>LAKIEROWANIE</StyledSectionTitle>
          <p>
            Oferta w zakresie lakierowania obejmuje kompleksowe usługi
            dostosowane do wymagań Klienta. Kompletnie wyposażone laboratorium
            pozwala nam w pełni kontrolować parametry procesów chemicznych oraz
            fizyczne i mechaniczne wyrobów gotowych.
          </p>
          <p>Wysoką jakość produktu gwarantują:</p>
          <StyledListParagraph>– kontrola połysku,</StyledListParagraph>
          <StyledListParagraph>
            – badanie koloru spektrofotometrem,
          </StyledListParagraph>
          <StyledListParagraph>– badanie przyczepności,</StyledListParagraph>
          <StyledListParagraph>
            – badanie twardości testem ołówkowym,
          </StyledListParagraph>
          <StyledListParagraph>– pomiar grubości powłoki,</StyledListParagraph>
          <StyledListParagraph>– próba zginania,</StyledListParagraph>
          <StyledListParagraph>– próba tłoczności,</StyledListParagraph>
          <StyledListParagraph>
            – próba odporności na uderzenia,
          </StyledListParagraph>
          <StyledListParagraph>– test na ścieralność.</StyledListParagraph>
        </StyledSection>
        <StyledSection>
          <StyledSectionTitle>
            FIRMA DYSPONUJE TRZEMA LINIAMI LAKIERNICZYMI:
          </StyledSectionTitle>
          <p>
            Linią lakierowania proszkowego przystosowaną do lakierowania
            materiałów wykonanych ze stali i stali ocynkowanej. Jest ona
            wyposażona w technologię przygotowania powierzchni opartą na
            procesie fosforanowania żelazowego, z opcjonalnym zastosowaniem
            pasywacji silanowej. Kabina lakiernicza z aplikacją automatyczną lub
            ręczną umożliwia zarówno szybką zmianę koloru farby proszkowej, jak
            i realizację krótkich partii lakierowanych wyrobów.
          </p>
          <p>
            Linią lakierowania proszkowego przystosowaną do lakierowania
            materiałów wykonanych ze stali nierdzewnych, stali zwykłych, stali
            ocynkowanej. Jej wyposażenie w siedmiostrefową linię przygotowania
            chemicznego powierzchni pozwala na zastosowanie procesów
            odtłuszczania (dla stali nierdzewnych), procesów fosforanowania
            żelazowego lub fosforanowania cynkowego, z końcową pasywacją
            cyrkonowo-silanową, lub tzw. nanotechnologii. Kabina lakiernicza, z
            aplikacją automatyczną oraz z automatycznym systemem filtracji,
            umożliwia osiągnięcie oczekiwanej jakości powłoki lakierniczej. Jest
            przeznaczona do lakierowania dużych partii produkcyjnych, zapewnia
            wysoką wydajność procesu.
          </p>
          <p>
            Linią lakierowania tworzyw sztucznych przeznaczoną do lakierowania
            jednowarstwowego lakierami ciekłymi rozpuszczalnikowymi i
            wodorozcieńczalnymi, w pełni automatyczną, z aplikacją robotem
            lakierniczym. Technologia przygotowania elementów z tworzyw
            sztucznych do lakierowania oparta jest na najnowszym systemie
            odmuchu zjonizowanym powietrzem. Dzięki zastosowaniu robota
            lakierniczego istnieje możliwość lakierowania detali o różnorodnych
            formach i kształtach. Całość uzupełnia sterowany procesorem system
            szybkiej zmiany kolorów, co gwarantuje wymaganą jakość i wydajność
            procesu. Projekt ten, pod nazwą „Uruchomienie usługi lakierowania
            detali z tworzyw sztucznych w oparciu o innowacyjną technologię”,
            został dofinansowany ze środków Unii Europejskiej. Jego wartość: 2
            806 000,00 PLN, wartość dofinansowania: 749 800,00 PLN
          </p>
          <StyledBoldParagraph>
            Każdorazowo oferujemy lakierowanie partii próbnej, aby umożliwić
            Klientowi weryfikację naszej oferty
          </StyledBoldParagraph>
        </StyledSection>
      </StyledContentWrapper>
    </>
  )
}

export const query = graphql`
  {
    maps_background: file(name: { eq: "maps_background" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    hero_image_painting: file(name: { eq: "hero_image_painting" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default PaintingPage
